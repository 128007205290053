// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.form-wrapper > div {
  display: flex;
  justify-content: center;
}

.login-form-container .form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  max-width: 314px;
  min-width: 314px;
}
.form-wrapper input,
select,
textarea {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid rgb(186, 191, 197);
}
.form-wrapper label {
  font-size: 15px;
  font-weight: 700;
}
.login-form {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.login-form > div {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.login-form > h1 {
  text-align: center;
}

.login-form button {
  width: 100%;
  margin-right: 0;
  text-align: center;
}

.login-form-input-container {
  flex-direction: column;
  gap: 8px;
}

.error-msg {
  color: #ff5a5f;
}
.signup-login-button {
  all: unset;
  font-family: "Fira Sans", sans-serif;
  cursor: pointer;
  padding: 0.7rem 1rem;
  background-color: #eeeeee;
  border-radius: 30px;
  margin-right: 1rem;
}
h3 {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./components/UserPage/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;AACA;;;EAGE,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,cAAc;AAChB;AACA;EACE,UAAU;EACV,oCAAoC;EACpC,eAAe;EACf,oBAAoB;EACpB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".login-form-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 16px;\n}\n\n.form-wrapper > div {\n  display: flex;\n  justify-content: center;\n}\n\n.login-form-container .form-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  flex-grow: 1;\n  max-width: 314px;\n  min-width: 314px;\n}\n.form-wrapper input,\nselect,\ntextarea {\n  padding: 8px;\n  border-radius: 6px;\n  border: 1px solid rgb(186, 191, 197);\n}\n.form-wrapper label {\n  font-size: 15px;\n  font-weight: 700;\n}\n.login-form {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n}\n.login-form > div {\n  margin-bottom: 8px;\n  display: flex;\n  justify-content: center;\n}\n.login-form > h1 {\n  text-align: center;\n}\n\n.login-form button {\n  width: 100%;\n  margin-right: 0;\n  text-align: center;\n}\n\n.login-form-input-container {\n  flex-direction: column;\n  gap: 8px;\n}\n\n.error-msg {\n  color: #ff5a5f;\n}\n.signup-login-button {\n  all: unset;\n  font-family: \"Fira Sans\", sans-serif;\n  cursor: pointer;\n  padding: 0.7rem 1rem;\n  background-color: #eeeeee;\n  border-radius: 30px;\n  margin-right: 1rem;\n}\nh3 {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
